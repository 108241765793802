$(function () {
    // ダイアログを既に見ている場合は非表示
    if (!$.cookie('notices_displayed')) {
        $('.notices-dialog-area').fadeIn(600);
        $.cookie('notices_displayed', 'true');
    }
    $('.close-notices-dialog, .notices-dialog-bg').on('click', function () {
        $('.notices-dialog-area').fadeOut();
    });
});
